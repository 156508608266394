<template>
    <div class="coursedetail">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="coursedetaildiv">
            <div class="coursedetaildiv_video">
                <div class="coursedetaildiv_video_left">
                    <div class="coursedetaildiv_video_left_player">
                        <img class="courseVideoImg" @click="clickImg()" :src="classDetail.coverPath" />
                    </div>
                </div>
                <div class="coursedetaildiv_video_right">
                    <p class="coursedetaildiv_video_right_title">{{ classDetail.courseName }}</p>
                    <p class="coursedetaildiv_video_right_tips" style="color:#666666;">{{ classDetail.courseSecondName }}</p>
                    <div class="coursedetaildiv_video_right_detailed">
                        <p v-if="classDetail.isFree != 0 && set_meal.discountPrice != null" style="display:flex;align-items: baseline;justify-content: flex-start;">
                            <span style="width:4em;">价格：</span>
                            <span class="color_red color_big">¥{{ set_meal.discountPrice }}</span>
                            <span class="color_delete">¥{{ set_meal.price }}</span>
                            <span style="flex: 1;display: flex;justify-content: flex-end;">折扣剩余时间: {{ discountCount }}</span>
                            <!-- margin-left:100px; -->
                        </p>
                        <p v-else-if="classDetail.isFree != 0 && set_meal.discountPrice == null" style="display:flex;align-items: baseline;justify-content: flex-start;">
                            <span style="width:4em;">价格：</span>
                            <span class="color_red color_big">¥{{ set_meal.price }}</span>
                        </p>

                        <p v-else style="display:flex;">
                            <span style="width:4em;">价格：</span>
                            <span class="color_blue color_big">免费</span>
                        </p>
                        <div class="coursedetaildiv_video_right_detailed_discounts" v-if="classDetail.isFree == 1 && classDetail.courseCouponList.length > 0">
                            <div class="coursedetaildiv_video_right_detailed_discounts_title">优惠劵</div>
                            <div class="coursedetaildiv_video_right_detailed_discounts_list">
                                <div class="coursedetaildiv_video_right_detailed_discounts_list_item" v-for="(item,index) in classDetail.courseCouponList" :key="index">
                                    <p>¥{{ item.price }}</p>
                                    <p v-if="item.isReceive == 0" @click="getCount(item, index)" style="cursor:pointer;">点击领取</p>
                                    <p v-else>已领取</p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="coursedetaildiv_video_right_detailed_presented"
                            v-if="classDetail.giveCourseNames != null && classDetail.giveCourseNames != undefined && JSON.stringify(classDetail.giveCourseNames) != '[]'"
                        >
                            <p>赠送课程</p>
                            <p style="display:flex;justify-content: space-between;width:calc(100% - 4em - 35px);margin-left:20px;">
                                <span style="display: flex;align-items: center;">
                                    <img src="@/assets/course/giveAway_icon.png" alt style="width:14px;height:14px;" />
                                    <span style="margin-left:5px;">{{ classDetail.giveCourseNames[0] }}</span>
                                </span>
                                <el-popover placement="bottom" width="300" trigger="hover" v-model="visible">
                                    <div class>
                                        <p v-for="(nameItmem,key) in classDetail.giveCourseNames" :key="key">
                                            <!-- <img src alt style="width:14px;height:14px;" /> -->
                                            <span>{{ nameItmem }}</span>
                                        </p>
                                    </div>
                                    <span slot="reference" @click="visible = !visible">
                                        <span>
                                            共
                                            <span style="color:#f00;">{{ classDetail.giveCourseNames.length }}</span>门课程
                                        </span>
                                        <img src alt style="width:15px;height:8px;" />
                                    </span>
                                </el-popover>
                            </p>
                        </div>
                    </div>

                    <div class="coursedetaildiv_video_right_detailed_p">
                        <div class="coursedetaildiv_video_right_detailed_p_title">特色服务</div>
                        <div class="coursedetaildiv_video_right_detailed_p_view">
                            <p v-for="(item,index) in classDetail.specialServiceNames" :key="index">
                                <!-- <img src="" alt="" style=""> -->
                                <!-- <span>{{ item }}</span> -->
                                <el-tag type="info" style="margin-right:10px;">{{ item }}</el-tag>
                            </p>
                        </div>
                    </div>
                    <div class="coursedetaildiv_video_right_detailed_p" v-if="classDetail.isFree == 1">
                        <div class="coursedetaildiv_video_right_detailed_p_title">有效期</div>
                        <div class="coursedetaildiv_video_right_detailed_p_view">
                            <el-select v-model="validity" @change="validityChange">
                                <el-option
                                    v-for="item in classDetail.coursePackagePriceList"
                                    :key="item.coursePackagePriceId"
                                    :label="classDetail.effectiveType == 1 ? item.effectiveEtimeName : item.effectiveMonthName"
                                    :value="item.coursePackagePriceId"
                                ></el-option>
                            </el-select>
                        </div>
                    </div>
                    <!-- <div class="coursedetaildiv_video_right_detailed_p">
                        <div class="coursedetaildiv_video_right_detailed_p_title">服务保障</div>
                        <div class="coursedetaildiv_video_right_detailed_p_view">购课协议、七天价保</div>
                    </div>-->
                    <div class="coursedetaildiv_video_right_detailed_p">
                        <el-button @click="goService()">在线咨询</el-button>
                        <div v-if="classDetail.isShow === 0">
                            <el-button type="primary" @click="signUp(1)" v-if="classDetail.isBuy == 0 && classDetail.isFree == 1">立即购买</el-button>
                            <el-button type="primary" @click="toMyStudy" v-if="classDetail.isFree == 0 || (classDetail.isFree == 1 && classDetail.isBuy == 1)">去学习</el-button>
                        </div>
                        <div v-else>
                            <el-button type="primary" >套餐已下架</el-button>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="coursedetaildiv_class">
                <div class="coursedetaildiv_class_left">
                    <div class="coursedetaildiv_class_left_chapter">
                        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                            <el-menu-item index="1">课程详情</el-menu-item>
                            <el-menu-item index="2">课程安排</el-menu-item>
                        </el-menu>
                        <div v-if="activeIndex == '1'" class="classDetail">
                            <p :class="islook ? '' : 'qiehuan'" v-html="classDetail.detail"></p>
                        </div>
                        <div v-if="activeIndex == '2'" class="coursedetaildiv_class_left_chapter_list">
                            <!-- <div
                                class="coursedetaildiv_class_left_chapter_list_header"
                                style="display:flex;align-items: center;justify-content: center;"
                            >
                                <span>课程安排</span>
                                <span>/</span>
                                <span>更合理</span>
                            </div>-->
                            <div hidden>
                                <div class="coursedetaildiv_class_left_chapter_list_item" v-for="(item,index) in classDetail.courseScheduleList" :key="index">
                                    <div class="coursedetaildiv_class_left_chapter_list_item_title">
                                        <!-- <img src="@/assets/exam/add.png" alt=""> -->
                                        <span>{{ item.courseName }}</span>
                                    </div>
                                    <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" text-color="#333" active-text-color="#ffd04b" @select="select">
                                        <el-submenu :index="JSON.stringify(key + 1)" v-for="(listItem,key) in item.chapterList" :key="key">
                                            <template slot="title">
                                                <span>{{ listItem.chapterName }}</span>
                                            </template>
                                            <el-menu-item v-for="(childer,childerIndex) in listItem.classHourList" :key="childerIndex" :index="JSON.stringify(childer.classHourId)">
                                                <div class style="width:100%;height:56px;display:flex;align-items: center;justify-content: space-between;box-sizing:border-box;">
                                                    <p style="width:60%;color:#555555;">{{ childer.classHourName }}</p>
                                                    <p style="display: flex;justify-content: space-between;align-items: center;">
                                                        <img
                                                            src="@/assets/course/play_icon.png"
                                                            alt
                                                            style="width:20px;height:20px;"
                                                            v-if="childer.isAudition == 1 || classDetail.isBuy == 1 || classDetail.isFree == 0"
                                                            @click="goVideo(item, key, childerIndex, childer)"
                                                        />
                                                        <img src="@/assets/course/suo.png" alt v-if="childer.isAudition == 0 && classDetail.isBuy == 0 && classDetail.isFree == 1" />
                                                        <!-- <span style="color:#A3A3A3;">{{ getHMS(childer.videoTimeLength) }}/{{ getHMS(childer.watchVideoTimeLength) }}</span> -->
                                                    </p>
                                                </div>
                                            </el-menu-item>
                                        </el-submenu>
                                    </el-menu>
                                </div>
                            </div>
                            <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" text-color="#333" active-text-color="#ffd04b">
                                <el-submenu :index="index" v-for="(item,index) in classDetail.courseScheduleList" :key="index">
                                    <template slot="title">
                                        <span>{{ item.courseName }}</span>
                                    </template>
                                    <el-submenu :index="`${index}-${key}`" v-for="(listItem,key) in item.chapterList" :key="key">
                                        <template slot="title">{{listItem.chapterName}}</template>
                                        <el-menu-item :index="`${index}-${key}-${childerIndex}`" v-for="(childer,childerIndex) in listItem.classHourList" :key="childerIndex">
                                            <div class style="width:100%;height:56px;display:flex;align-items: center;justify-content: space-between;box-sizing:border-box;">
                                                <p style="width:60%;color:#555555;font-size: 14px;">{{ childer.classHourName }}</p>
                                                <p style="display: flex;justify-content: space-between;align-items: center;">
                                                    <img
                                                        src="@/assets/course/play_icon.png"
                                                        alt
                                                        style="width:20px;height:20px;"
                                                        v-if="childer.isAudition == 1 || classDetail.isBuy == 1 || classDetail.isFree == 0"
                                                        @click="goVideo(item, key, childerIndex, childer)"
                                                    />
                                                    <img src="@/assets/course/suo.png" alt v-if="childer.isAudition == 0 && classDetail.isBuy == 0 && classDetail.isFree == 1" />
                                                    <!-- <span style="color:#A3A3A3;">{{ getHMS(childer.videoTimeLength) }}/{{ getHMS(childer.watchVideoTimeLength) }}</span> -->
                                                </p>
                                            </div>
                                        </el-menu-item>
                                        <!-- <el-menu-item index="1-1-2">选项1</el-menu-item> -->
                                    </el-submenu>
                                </el-submenu>
                            </el-menu>
                        </div>
                    </div>
                </div>
                <div class="coursedetaildiv_class_right">
                    <div class="coursedetaildiv_class_right_counselor">
                        <div class="coursedetaildiv_class_right_counselor_header">
                            <img src="@/assets/course/bubble.png" alt />
                            <span>添加课程顾问</span>
                        </div>
                        <div class="coursedetaildiv_class_right_counselor_serve">
                            <p>
                                <img src="@/assets/course/Information.png" alt />
                                <span>获取资料</span>
                            </p>
                            <p>
                                <img src="@/assets/course/exclusive.png" alt />
                                <span>专属服务</span>
                            </p>
                        </div>
                        <div class="coursedetaildiv_class_right_counselor_QRcode">
                            <img :src="classDetail.adviserQrCode" alt style="width:136px;height:136px;" />
                            <!-- <p>微信号:792340jeeam</p>
                            <p>马上使用微信扫码添加吧</p>-->
                        </div>
                    </div>
                    <div class="coursedetaildiv_class_right_recommend">
                        <div class="coursedetaildiv_class_right_recommend_header">
                            <img src="@/assets/course/bubble.png" alt style="width:20px;height:20px;" />
                            <span>热门课程</span>
                        </div>
                        <div class="coursedetaildiv_class_right_recommend_list">
                            <div style="cursor:pointer;" class="coursedetaildiv_class_right_recommend_list_item" v-for="(item,index) in recommendList" :key="index" @click="todetail(item)">
                                <img :src="item.coverPath" alt style="width:100%;height:164px;" />
                                <div class="coursedetaildiv_class_right_recommend_list_item_content">
                                    <p class="coursedetaildiv_class_right_recommend_list_item_content_name">{{ item.packageName }}</p>
                                    <div v-if="item.isFree == 1" class="coursedetaildiv_class_right_recommend_list_item_content_price">
                                        <div v-if="item.discountPrice">
                                            <span style="color:red; margin-right:10px;">限时折扣：¥ {{ item.discountPrice }}</span>
                                            <span style="text-decoration: line-through;">{{ item.price }}</span>
                                        </div>
                                        <div v-if="!item.discountPrice">
                                            <span style="color:red;">{{ item.price }}</span>
                                        </div>
                                    </div>
                                    <p v-if="item.isFree == 0" class="coursedetaildiv_class_right_recommend_list_item_content_price">
                                        <span style="color:#5F7CFD !important">免费</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="recommend" v-if="recommendList.length != 0">
            <div class="coursedetaildiv_title">推荐课程</div>
            <div class="recommend_list">
                <div  v-for="(item,index) in recommendList" :key="index" @click="todetail(item)">
                    <img
                        :src="item.coverPic"
                        v-if="item.coverPic != null && item.coverPic != '' && item.coverPic != undefined"
                    />
                    <p class="recommend_list_people">{{ item.willNum }}人已学</p>
                    <p class="recommend_list_title">{{ item.name }}</p>
                    <p v-if="item.totalPrices != 0">
                        <span class="color_red">¥{{ item.totalPrices }}</span>
                        <span class="color_ccc">¥{{ item.originalPrice }}</span>
                    </p>
                    <p v-if="item.totalPrices == 0">
                        <span class="color_blue">免费</span>
                    </p>
                </div>
            </div>
        </div>-->
        <div class="home_position">
            <div class="home_position_top">
                <p @click="goService()">
                    <img src="@/assets/home/service.png" alt />
                </p>
                <p>
                    <el-popover placement="left" width="120" trigger="hover">
                        <img :src="website.qrCodeSidebar" alt style="width:120px;height:120px;" />
                        <el-button slot="reference">
                            <img src="@/assets/home/WX_icon.png" alt />
                        </el-button>
                    </el-popover>
                </p>
                <p>
                    <el-popover placement="left" width="120" trigger="hover">
                        <span>{{website.telephone}}</span>
                        <el-button slot="reference">
                            <img src="@/assets/home/mobile_icon.png" alt />
                        </el-button>
                    </el-popover>
                </p>
            </div>
            <div class="home_position_bottom">
                <p>
                  <el-popover placement="left" width="120" trigger="hover">
                    <img :src="website.qrCodeApp" alt="" style="width:120px;height:120px;">
                    <el-button slot="reference" style="margin: 0;padding: 0;border: 0;">
                        <img src="@/assets/home/APP_icon.png" alt="">
                    </el-button>
                  </el-popover>
                    <!-- <img :src="website.qrCodeApp" alt /> -->
                </p>
                <p @click="toTop">
                    <img src="@/assets/home/back_top.png" alt />
                </p>
            </div>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { formatDate } from './date.js';
import { videoPlayer } from 'vue-video-player';
// import Swiper from "swiper"
// import { course_ware_list,course_video_list,apply_course,plan_reported_data,order_course_video_list,h5_course_alipay,h5_course_wxpay,h5_pay_order_type,get_related_courses,course_dataClass_list } from "@/api/course";
import { package_detail, course_getCoupon, course_recommendList, free_connect } from "@/api/course";
import { wangzhanpeizhi } from "@/api/login";
export default {
    name: 'coursedetail',
    components: {
        t_header,
        t_footer,
    },
    filters: {
        formatDate(time) {
            var date = new Date(time);
            return formatdate(date, 'yyyy-MM-dd hh:mm:ss');  // 此处可根据自己的需要自定义想要的日期格式
        }
    },
    data() {
        return {
            validity: '',//有效期
            fullscreenLoading: false,// 支付弹框
            isBuyCourse: 2,//1.是 2.不是 (购买)
            courseScheduleList: [],//课件列表
            coursePackageId: '',//详情id
            warePage: 0,//课件分页
            videoPage: 0,//视频分页
            classDetail: {},//视频详情对象
            courseScheduleList: [],//课程安排列表
            courseVideoCount: 0,//视频总条数
            courseVideoIndex: 0,
            videoObj: {},
            islook: false,//课程查看更多
            isLookText: '查看更多',//课程查看更多的按钮
            isLookVideo: false,//课程列表查看更多
            isLookVideoText: '加载更多',
            isLookNewsText: '',
            applydata: {},
            centerDialogVisible: false,
            videolong: '',
            videostarttimeplan: 0,
            ispay: false,
            radio: 2,
            recommendPage: 0,
            recommendList: [],//推荐课程
            bouncedContentNum: 0,//课程介绍字数
            activeIndex: '1',
            classNewsList: [],//课程资料列表
            classDetail: {},
            set_meal: {},//套餐对象
            discountLeftTime: '', //折扣剩余时间 - 秒数
            discountCount: '',//折扣剩余时间 - 显示
            isType: '1',
            isSort: false,
            todos: false,
            options: [],
            coursePackagePriceList: [],
            visible: false,
            isUserInfo: false,
            globalTime: '',
            website:{},
        }
    },
    mounted() {
        this.coursePackageId = this.$route.query.coursePackageId
        console.log(this.$route.query.coursePackageId)
        this.isFree = this.$route.query.isFree
        if (this.$route.query.wareIndex) {
            this.wareIndex = this.$route.query.wareIndex
        }
        // setTimeout(() => {
        //     // this._swiper();
        // }, 1000);
        // this.getCourseWareList();
        // this.getRecommend()
        // this.getindustry_list()
        // this.get_related_courses()
        // var player = videojs('player');
        // player.logo({

        // });
        this.package_detail()
        this.course_recommendList()
        // this.free_connect();
        this.wangzhanpeizhi();
    },
    computed: {

        //   player() {
        //     return this.$refs.videoPlayer.player
        //   }
    },
    watch: {
        $route(to, from) {
            console.log(from.path);//从哪来
            console.log(to.path);//到哪去
            window.scrollTo(0, 0);
            this.courseId = to.query.courseId
            this.isBuyCourse = to.query.isBuy
            this.wareIndex = 0
            this.warePage = 0
            this.courseWareList = []
            this.videoPage = 0
            this.courseVideoIndex = 0
            this.courseScheduleList = []
            // this._swiper();
            setTimeout(() => {
                // this._swiper();
            }, 1000);
            this.getCourseWareList();
        },
        centerDialogVisible(oldvalue, newvalue) {
            console.log(oldvalue)
            console.log(newvalue)
            if (oldvalue == false) {
                this.$refs.qrcode.innerHTML = "";//清空渲染的二维码
            }
        }
    },
    methods: {
      wangzhanpeizhi(){
        wangzhanpeizhi().then(res=>{
            if(res.data.code == 0){
                this.website = res.data.data;
            }
        }).catch(error=>{
            console.log(error);
        })
    },
        getHMS(time) {
            const hour = parseInt(time / 3600) < 10 ? '0' + parseInt(time / 3600) : parseInt(time / 3600)
            const min = parseInt(time % 3600 / 60) < 10 ? '0' + parseInt(time % 3600 / 60) : parseInt(time % 3600 / 60)
            const sec = parseInt(time % 3600 % 60) < 10 ? '0' + parseInt(time % 3600 % 60) : parseInt(time % 3600 % 60)
            if (hour == '00') {
                return min + ':' + sec;
            } else {
                return hour + ':' + min + ':' + sec
            }
        },
        // 父传子
        getTodos(e) {
            console.log(e)
            this.isUserInfo = e
        },
        // 推荐课程
        course_recommendList() {
            course_recommendList().then(res => {
                console.log(res)
                if (res.data.code == 0) {
                    this.recommendList = res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        checkLogin() {
            const userInfo = localStorage.getItem("setuserInfo");
            if (userInfo == null || userInfo == 'null') {
                this.isUserInfo = true;
                setTimeout(() => {
                    this.isUserInfo = false;
                }, 0);
                return false
            } else {
                return userInfo
            }
        },
        //   领取优惠券
        getCount(item, key) {
            const userInfo = this.checkLogin();
            if (!userInfo) {
                return false
            }
            var obj = {
                courseCouponId: item.courseCouponId
            }
            course_getCoupon(obj).then(res => {
                console.log(res)
                if (res.data.code == 0) {
                    this.$message.success('领取成功');
                    var classDetail = this.classDetail;
                    classDetail.courseCouponList[key].isReceive = 1;
                    this.classDetail = classDetail;

                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        //   返回顶部
        toTop() {
            let top = document.documentElement.scrollTop || document.body.scrollTop;
            //实现滚动效果
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
                if (top <= 0) {
                    clearInterval(timeTop);
                }
            }, 10);
        },
        //   去客服
        goService() {
            var url = 'https://tb.53kf.com/code/client/10093167/1';
            // let routeData = this.$router.resolve({ path: url, query: {} }); 
            window.open(url, '_blank');
        },
        goVideo(item, key, childerIndex, childer) {
            const userInfo = this.checkLogin();
            if (!userInfo) {
                return false
            }
            // return
            this.videoObj = item.chapterList[key].classHourList[childerIndex]
            console.log(this.videoObj)
            console.log(childer);

            let routeUrl = this.$router.resolve({
                path: '/videoRoom',
                query: {
                    videoObj: JSON.stringify(this.videoObj),
                    // coursePackageId: this.classDetail.coursePackageId,
                    // classDetail: JSON.stringify(this.classDetail),
                    clickItem: JSON.stringify(item),
                    classDetail: JSON.stringify(this.classDetail),
                    coursePackageId: this.coursePackageId,
                    studyTimeLength: childer.watchVideoTimeLength,
                    type: 0
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        handleOpen() {

        },
        handleClose() {

        },
        select(key, keyPath) {
            console.log(key)
            console.log(keyPath)
        },
        remainder: function (num) {
            num = parseInt(num, 10);
            return num > 9 ? num : "0" + num;
        },
        // 秒数 --> 时：分：秒
        formatTime: function (leftTime) {
            let discountCount = ''
            if (leftTime) {
                let D = this.remainder(leftTime / 60 / 60 / 24);
                let H = this.remainder(leftTime / 60 / 60 % 24);
                let M = this.remainder(leftTime / 60 % 60);
                let S = this.remainder(leftTime % 60);
                D > 0 && (discountCount += `${D}天`)
                H > 0 && (discountCount += `${H}时`)
                M > 0 && (discountCount += `${M}分`)
                discountCount += `${S}秒`
            }
            return discountCount || 0;
        },

        updateCountDown(time) {
            this.discountLeftTime = time;
            this.discountCount = this.formatTime(time)
            time--;
            if (time > 0) {
                this.globalTime = setTimeout(() => {
                    this.updateCountDown(time)
                }, 1000);
            }
        },
        // 点击购买
        signUp(num) {
            const userInfo = this.checkLogin();
            if (!userInfo) {
                return false
            }
            let routeUrl = this.$router.resolve({
                path: '/orderInfo',
                query: {
                    coursePackageId: this.classDetail.coursePackageId,
                    coursePackagePriceId: this.set_meal.coursePackagePriceId,
                    type: num,
                    isTextbook: this.classDetail.isTextbook,
                    classDetail: JSON.stringify(this.classDetail)
                }
            });
            window.open(routeUrl.href, '_blank');


            // num 1、是课程购买 2、是直播购买

        },
        // 去学习
        toMyStudy() {
            const userInfo = this.checkLogin();
            if (!userInfo) {
                return false
            }
            // this.$router.push('/myself/myClass')
            let routeUrl = this.$router.resolve({
                path: '/myself/myClass',
                query: {}
            });
            window.open(routeUrl.href, '_blank');
        },
        payClose() { },
        tonext() { },
        // 选择课程列表还是资料列表
        handleSelect(key, keyPath) {
            console.log(key)
            this.activeIndex = key
            if (key == 2) {
                // this.course_dataClass_list()
            }
        },
        // 课程介绍查看更多
        lookAll() {
            this.islook = !this.islook
            if (this.islook == true) {
                this.isLookText = "收起查看"
            } else {
                this.isLookText = "查看更多"
            }
        },
        // 课程列表查看更多
        lookVideoMore() {
            if (this.courseVideoCount > this.courseScheduleList.length) {
                this.getCourseVideoVist({ page: this.videoPage + 1, wareId: this.courseWareList[this.wareIndex].wareId })
            } else {
                this.isLookVideo = true
                this.isLookVideoText = "已加载全部"
            }
        },
        // 去详情
        todetail(item) {
            console.log(item);
            // const userInfo = localStorage.getItem("setuserInfo");
            // console.log(userInfo);
            // if(userInfo==null || userInfo=='null'){
            //     this.isUserInfo=true
            //     return false
            // }
            let routeUrl = this.$router.resolve({
                path: '/coursedetail',
                query: { coursePackageId: item.coursePackageId }
            });
            window.open(routeUrl.href, '_blank');

        },
        // // 去详情
        // tonewdetail(item){
        //     this.$router.push({
        //         path: '/realtimeinfodetail',
        //         query: {industryId:item.id}
        //     });
        // },
        // 选择课件
        chooseWare(item, index) {
            this.wareIndex = index
            this.videoPage = 0
            this.courseVideoIndex = 0
            this.courseVideoList = []
            this.getCourseVideoVist()
        },
        // 课程详情+视频列表
        package_detail() {
            var that = this
            var obj = {
                coursePackageId: that.coursePackageId
            }
            package_detail(obj).then(res => {
                console.log(res)
                if (res.data.code == 0) {
                    // that.classDetail=res.data.data
                    // that.set_meal=res.data.data.coursePackagePriceList[0]
                    // var arr=res.data.data.coursePackagePriceList
                    // if(arr.length>0){
                    //     for(var i in arr){
                    //         arr[i].effectiveEtimeName='购课之日至'+arr[i].effectiveEtime
                    //         arr[i].effectiveMonthName=arr[i].effectiveMonth+'个月'
                    this.classDetail = res.data.data
                    document.title = res.data.data.courseName + '-萨恩课堂';
                    this.set_meal = res.data.data.coursePackagePriceList[0]
                    if (this.set_meal) {
                        //   let lettTime = this.set_meal.discountLeftTime
                        this.set_meal.discountLeftTime && this.updateCountDown(this.set_meal.discountLeftTime)
                    }
                    var arr = res.data.data.coursePackagePriceList
                    if (arr.length > 0) {
                        for (var i = 0; i < arr.length; i++) {
                            let item = arr[i]
                            console.log(item.effectiveEtime);
                            item.effectiveEtimeName = '购课之日至' + this.format(item.effectiveEtime);
                            item.effectiveMonthName = item.effectiveMonth + '个月'
                        }
                        if (res.data.data.effectiveType == 0 && res.data.data.coursePackagePriceList.length > 0) {
                            that.validity = arr[0].effectiveMonthName
                        } else {
                            that.validity = arr[0].effectiveEtimeName
                        }
                        that.classDetail.coursePackagePriceList = arr
                    }
                    if (this.classDetail.isFree == 0) {
                        this.free_connect();
                    }
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(error => {
                console.log(error)
            })
        },
        add0(m) {
            return m < 10 ? '0' + m : m
        },
        //免费课程绑定关系
        free_connect() {
            var obj = {
                coursePackageId: this.classDetail.coursePackageId
            }
            free_connect(obj).then(res => {
                console.log(res)

            }).catch(error => {
                console.log(error)
            })
        },
        format(shijianchuo) {
            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(shijianchuo);
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            return y + '-' + this.add0(m) + '-' + this.add0(d);// '+this.add0(h)+':'+this.add0(mm)+':'+this.add0(s);
        },

        validityChange(value) {
            console.log(value)
            for (var i in this.classDetail.coursePackagePriceList) {
                if (this.classDetail.coursePackagePriceList[i].coursePackagePriceId == value) {
                    this.set_meal = this.classDetail.coursePackagePriceList[i]
                    if (this.set_meal) {
                        clearTimeout(this.globalTime);
                        //   let lettTime = this.set_meal.discountLeftTime
                        this.set_meal.discountLeftTime && this.updateCountDown(this.set_meal.discountLeftTime)

                    }
                }
            }
        },

    },
    beforeDestroy() {
        if (this.isBuyCourse == 1) {
            this.planReportedData()
        }
    },

}
</script>